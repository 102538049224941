
import { Component, Vue } from "vue-property-decorator";
import i18n from "../../plugins/i18n";
import PersonService from "../../services/societymgmt/PersonService";
import Person from "../../models/societymgmt/Person";

@Component({})
export default class PersonsComponent extends Vue {
  // Loading
  public loading = true;
  // Table
  public items: Person[] = [];
  public fields = [
    {
      key: "firstname",
      sortable: true,
      label: i18n.tc("societymgmt.firstname"),
    },
    {
      key: "lastname",
      sortable: true,
      label: i18n.tc("societymgmt.lastname"),
    },
    {
      key: "dateOfBirth",
      sortable: true,
      label: i18n.tc("societymgmt.dateOfBirth"),
    },
    {
      key: "gender",
      sortable: true,
      label: i18n.tc("societymgmt.gender"),
    },
    {
      key: "oetbWienID",
      sortable: true,
      label: "ÖTB-Wien-ID",
    },
    {
      key: "actions",
      sortable: false,
      label: "",
    },
  ];
  public filter = "";
  public perPage = 10;
  public pageOptions = [5, 10, 20, 30];
  public totalRows = 1;
  public currentPage = 1;

  async mounted(): Promise<void> {
    this.loading = true;

    const items = await PersonService.getAll(true);

    console.log("persons here:", items);
    if (items !== undefined) {
      this.items = items;
      this.totalRows = this.items.length;
    }
    this.loading = false;
  }
}
